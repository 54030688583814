import { template as template_da8f4bc9ce574bd09a217772022d4460 } from "@ember/template-compiler";
const FKText = template_da8f4bc9ce574bd09a217772022d4460(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
