import { template as template_353d0c7528a84155a9d8eab635f8b308 } from "@ember/template-compiler";
const FKLabel = template_353d0c7528a84155a9d8eab635f8b308(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
